<script setup lang="ts">
import { LButton } from '@better-software/lucy-ui';
import { useAuthStore } from '@/modules/auth/auth.store';

const authStore = useAuthStore();
</script>

<template>
    <section class="home">
        <h1>Home</h1>
        <p>Welcome to the Home page.</p>
        <LButton @click="authStore.startAuthentication">Log in</LButton>
    </section>
</template>
