import { defineStore } from 'pinia';
import { ref } from 'vue';
import { UserService } from '@/modules/user/user.service';
import { useInstituteStore } from '@/modules/instute/institute.store';

interface UserProfile {
    defaultInstituteId?: string;
}

export const useUserStore = defineStore('user', () => {
    const profile = ref<UserProfile | null>(null);
    const service = new UserService();

    async function refreshProfile() {
        const response = await service.getProfile();
        profile.value = response.data;
        const instituteStore = useInstituteStore();
        instituteStore.setCurrentInstituteId(profile.value?.defaultInstituteId);
    }

    return {
        profile,
        refreshProfile,
    };
});
