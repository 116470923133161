import { ApiClient } from '@/core/api-client';

export class UserService extends ApiClient {
    constructor() {
        super('account');
    }

    async getProfile() {
        return this.client.get(`/profile`);
    }
}
