import { useAuthStore } from '@/modules/auth/auth.store';
import { unref } from 'vue';
import { watchEffectOnceAsync } from '@/core/watch';

async function createGuardHandler(store: ReturnType<typeof useAuthStore>) {
    const fn = async () => {
        if (unref(store.isLoggedIn)) {
            return true;
        }

        await store.startAuthentication();

        return false;
    };

    if (unref(store.initialized)) {
        return fn();
    }

    await watchEffectOnceAsync(() => unref(store.initialized));

    return fn();
}

export async function authGuard() {
    const authStore = useAuthStore();
    return createGuardHandler(authStore);
}
