<template>
    <div class="block-loading-container">
        <div class="block-loading">
            <div class="block-loading__spinner"></div>
            <span> Loading... </span>
        </div>
    </div>
</template>

<style scoped lang="scss">
.block-loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}
.block-loading {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 1.5rem;
    color: #333;
    padding: 16px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 16px;
    min-height: 100px;
    min-width: 200px;
    .block-loading__spinner {
        border: 4px solid rgba(0, 0, 0, 0.1);
        border-top: 4px solid #333;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
    }
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
}
</style>
