import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import authRouter from '@/modules/auth/auth.router';
import { authGuard } from '@/modules/auth/auth.guard';
import onboardingRouter from '@/modules/onboarding/onboarding.router';

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView,
        },
        onboardingRouter,
        {
            path: '/app',
            name: 'app',
            component: () => import('../modules/layout/AppLayout.vue'),
            beforeEnter: authGuard,
            children: [],
        },
        authRouter,
    ],
});

export default router;
