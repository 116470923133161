import axios, {
    type AxiosInstance,
    type AxiosRequestHeaders,
    type InternalAxiosRequestConfig,
} from 'axios';
import { useAuthStore } from '@/modules/auth/auth.store';
import { unref } from 'vue';

export abstract class ApiClient {
    private readonly baseURL: string;
    public readonly client: AxiosInstance;

    protected constructor(prefix?: string) {
        const apiURL = import.meta.env.VITE_API_URL as string;
        this.baseURL = prefix ? `${apiURL}/${prefix}` : apiURL;
        this.client = this.createClient();
    }

    private createClient() {
        const instance = axios.create({
            baseURL: this.baseURL,
            headers: {
                'Content-Type': 'application/json',
            },
        });
        instance.interceptors.request.use(this.onRequest.bind(this));
        instance.interceptors.response.use(
            (response) => response,
            this.onResponseError.bind(this),
        );
        return instance;
    }

    private async onResponseError(error: any) {
        const authStore = useAuthStore();
        if (error.response?.status === 401) {
            await authStore.startAuthentication();
        }
        return Promise.reject(error);
    }

    private onRequest(config: InternalAxiosRequestConfig) {
        const authStore = useAuthStore();
        if (unref(authStore.isLoggedIn)) {
            config.headers ??= {} as AxiosRequestHeaders;
            config.headers.Authorization = `Bearer ${authStore.token}`;
        }
        return config;
    }
}
