import { defineStore } from 'pinia';
import { useSessionStorage } from '@vueuse/core';

export const useInstituteStore = defineStore('institute', () => {
    const currentInstituteId = useSessionStorage<Maybe<string>>(
        'currentInstituteId',
        null,
    );

    function setCurrentInstituteId(id?: string) {
        currentInstituteId.value = id;
    }

    return {
        currentInstituteId,
        setCurrentInstituteId,
    };
});
