import type { RouteRecordRaw } from 'vue-router';

export default {
    path: '/auth',
    name: 'auth',
    redirect: { name: 'login' },
    children: [
        {
            path: 'callback',
            name: 'callback',
            component: () => import('./callback/CallbackView.vue'),
        },
        {
            path: 'logout-callback',
            name: 'logout-callback',
            component: () => import('./callback/LogoutCallbackView.vue'),
        },
        {
            path: 'login',
            name: 'login',
            component: () => import('./login/LoginView.vue'),
        },
        {
            path: 'register',
            name: 'register',
            component: () => import('./register/RegisterView.vue'),
        },
    ],
} satisfies RouteRecordRaw;
