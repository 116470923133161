import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { User, UserManager, WebStorageStateStore } from 'oidc-client-ts';
import { useUserStore } from '@/modules/user/user.store';
import { randomString } from '@/core/random';

const identityUrl = import.meta.env.VITE_IDENTITY_URL as string;

const userManager = new UserManager({
    authority: identityUrl,
    // scope: 'openid email roles',
    client_id: 'console',
    redirect_uri: window.location.origin + '/auth/callback',
    post_logout_redirect_uri: `${window.location.origin}/auth/logout-callback`,
    response_type: 'code',
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    stateStore: new WebStorageStateStore({ store: window.localStorage }),
});

export const useAuthStore = defineStore('auth', () => {
    const user = ref<Maybe<User>>(null);
    const initialized = ref(false);
    const isLoggedIn = computed(() => !!user.value?.access_token);
    const token = computed(() => user.value?.access_token);

    userManager.events.addUserLoaded(async (loadedUser) => {
        user.value = loadedUser;
        const userStore = useUserStore();
        await userStore.refreshProfile();
    });

    userManager.events.addUserUnloaded(() => {
        user.value = null;
    });

    userManager.getUser()?.then(async (loadedUser) => {
        initialized.value = true;
        if (!loadedUser) {
            return;
        }
        user.value = loadedUser;
        const userStore = useUserStore();
        await userStore.refreshProfile();
    });

    function startAuthentication() {
        return userManager.signinRedirect();
    }

    async function signinCallback() {
        return await userManager.signinCallback();
    }

    async function logout() {
        await userManager.signoutRedirect({
            state: randomString(16),
        });
    }

    return {
        token,
        user,
        isLoggedIn,
        initialized,
        logout,
        startAuthentication,
        signinCallback,
    };
});
